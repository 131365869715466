import Vue from 'vue/dist/vue.esm'

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    el: '#app',
    data: {
      responsiveNav: false,

      property: false,
      house:  false,
      ask_org: false,
      termsAccepted: false
    },
    components: {  },
    methods: {
      setTargetType(event) {
        this.property = false
        this.house = false

        if (event.target.value == "suunnittelija" || event.target.value == "teollisuus") {
          this.ask_org = true
        } else {
          this.ask_org = false
        }

        if (event.target.value == "taloyhtio") {
          this.property = true
          this.house = false
        }
        if (event.target.value == "kaivovesi") {
          this.property = false
          this.house = true
        }
      },
      openTermsModal() {
        const modal = document.getElementById('terms-modal');
        modal.classList.add('is-active');
      },
      closeTermsModal() {
        const modal = document.getElementById('terms-modal');
        modal.classList.remove('is-active');
      }
    }
  })
})

